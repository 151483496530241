import React, { useState } from 'react'

const UpcomingAppointments = () => {
    const userId = localStorage.getItem('userId')
    const [appointmentList, setAppointmentList] = useState([])
  return (
    <div className='upcomingAppointmentWrapper'>
        <h1>Upcoming Appointments</h1>
        <div className='appointmentList'>
            {
                !userId ? "Login to see your upcoming appointments" 
                : !appointmentList.length > 0 ? "You have no upcoming appointments" 
                // : appointmentList?.map((appointment, index) => {
                //     return (
                //         <div key={index}></div>
                //     )
                // }) 
                : <>
                    <div className='appointmentCard'>
                        <p className='appointmentMonth'>Aug</p>
                        <p className='appointmentDate'>12</p>
                    </div>
                </>
            }
        </div>
    </div>
  )
}

export default UpcomingAppointments