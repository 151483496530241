import React from 'react'
import { Link } from 'react-router-dom'
import Search from '../Search'
import Logo from '../../../Assets/poochkuLogo.png'
import User from '../../../Assets/user.png'
import './style.scss'

const PageHeaderSticky = ({search, heading, subHeading}) => {
  return (
    <div className='pageHeadingSticky'>
            <Link to="/" className={'mobileHomeLink'}>
                <img src={Logo}/>
            </Link>
            <div>
              <h1 className='buyPageHeading font-face-D'>{heading ? heading : "Dashboard"}</h1>
              {subHeading && <p className='buyPageInfo'>{subHeading}</p>}
            </div>
            <Link to="/useraccount" className='userMenu'>
              <img src={User}/>
            </Link>
            {/* <Search
                populatePupList={value === "buy" ? getBuyPupList : getAdoptPupList}
                selectedBreed={selectedBreed}
                setSelectedBreed={setSelectedBreed}
                selectedGender={selectedGender}
                setSelectedGender={setSelectedGender}
                selectedQuality={selectedQuality}
                setSelectedQuality={setSelectedQuality}
                selectedState={selectedState}
                setSelectedState={setSelectedState}
            /> */}
          </div>
  )
}

export default PageHeaderSticky