import { useEffect, useState } from 'react';
import PhoneInput from "react-phone-input-2"
import Send from './send';
import InputOtp from './OtpInput';
import otpApi from '../../../services/otpApi';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../Auth/AuthContext";
import './style.scss'
import ReplayIcon from '@mui/icons-material/Replay';
import FirstLogin from '../../Reusable/FirstLogin';
import { useSelector } from 'react-redux';

const OtpController = ({isPopup, setOpen}) => {
    const { isLoggedIn, login, logout } = useAuth();
    const [otp, setOtp] = useState('');
    const [appState, setAppState] = useState('');
    const [otpVerifyDto, setOtpVerifyDto] = useState({});
    const location = useLocation();
    const { prevPath } = location.state || { prevPath: "/" };
    const profileStatus = useSelector(store => store.user.profileStatus)
    const userId = localStorage.getItem("userId")


    const tryAgain = () => {
        setAppState("review")
    }
    
    const getComponentFromAppState = () => {
        if(appState==='review'){
            return <Send isPopup={isPopup} setAppState={setAppState} setOtpVerifyDto={setOtpVerifyDto}/>;
        } else if(appState==='otpinput'){
            return <InputOtp isPopup={isPopup} setOpen={setOpen} setAppState={setAppState} otpVerifyDto={otpVerifyDto} prevPath={prevPath}/>;
        } else if(appState==='success'){
            return (<div>Successfully verfied</div>)
        } else if(appState === 'incomplete'){
            return (<FirstLogin />)
        }
        return (<>
            <div>Wrong OTP</div>
            <button onClick={tryAgain}>Try Again <ReplayIcon /></button>
        </>)
    }

    useEffect(() => {
        if(!userId){
            setAppState('review')
        }else if(userId && !profileStatus){
            setAppState('incomplete')
        }
    }, [profileStatus])
    
  return (
    <div className='otpWrapper'>
        {   
            getComponentFromAppState()
        }
    </div>
  );
}
export default OtpController;


