import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.scss'

const GoBack = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { prevPath } = location.state || { prevPath: "/" };

    const goBack = () => {
        navigate(prevPath)
    }


  return (
    <div className='goBackWrapper' onClick={() => goBack()}>
        <div>
            <ArrowBackIosIcon className='backIcon'/>
            <p>Go Back</p>
        </div>
    </div>
  )
}

export default GoBack