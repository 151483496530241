import Dog from '../../../Assets/dog.png'
import WhiteDog from '../../../Assets/dog-white.png'
import Adopt from '../../../Assets/animal-rights.png'
import WhiteAdopt from '../../../Assets/animal-rights-white.png'
import Mating from '../../../Assets/gender-fluid.png'
import WhiteMating from '../../../Assets/gender-fluid-white.png'
import Shop from '../../../Assets/pet-shop.png'
import WhiteShop from '../../../Assets/pet-shop-white.png'
import User from '../../../Assets/user.png'
import WhiteUser from '../../../Assets/user-white.png'
import Doctor from '../../../Assets/doctorIcon-black.png'
import WhiteDoctor from '../../../Assets/doctorIcon-white.png'
import DogWalk from '../../../Assets/dogWalkIcon-black.png'
import WhiteDogWalk from '../../../Assets/dogWalkIcon-white.png'
import Logo from '../../../Assets/poochkuLogo.png'
import './style.scss'
import { Link, useLocation } from 'react-router-dom'
import { memo } from 'react'

const MemomisedMobileNav = () => {
    const location = useLocation();
    const loc = location.pathname;
  return (
    <div className='mobileNav'>
        <Link to="/browse" className={loc.includes("browse") ? 'DhashNavLink active' : 'DhashNavLink'}>
          {/* <img className='DashNavIcon' src={Browse} /> */}
          <img src={loc.includes("browse") ? WhiteDog : Dog}/>
        </Link>
        <Link to="https://shop.poochku.in" className={loc.includes("shop") ? 'DhashNavLink active' : 'DhashNavLink'}>
          {/* <img className='DashNavIcon' src={PetShop}/> */}
          <img src={loc.includes("shop") ? WhiteShop : Shop}/>
        </Link>
        <Link to="/vets" className={(loc.includes("vets") || loc.includes("doctor") || loc.includes("clinic")) ? 'DhashNavLink active' : 'DhashNavLink'}>
          {/* <img className='DashNavIcon' src={PetShop}/> */}
          <img src={(loc.includes("vets") || loc.includes("doctor") || loc.includes("clinic")) ? WhiteDoctor : Doctor}/>
        </Link>
        {/* <Link to="/" className={'DhashNavLink mobileHomeLink'}>
          <img className='DashNavIcon' src={adopt}/>
          <img src={Logo}/>
        </Link> */}
        <Link to="/services" className={loc.includes("services") || loc.includes("service") ? 'DhashNavLink active' : 'DhashNavLink'}>
          {/* <img className='DashNavIcon' src={dog}/> */}
          <img src={loc.includes("services") || loc.includes("service") ? WhiteDogWalk : DogWalk}/>
        </Link>
        <Link to="/mating" className={loc.includes("mating") ? 'DhashNavLink active' : 'DhashNavLink'}>
          {/* <img className='DashNavIcon' src={dog}/> */}
          <img src={loc.includes("mating") ? WhiteMating : Mating}/>
        </Link>
        {/* <Link to="/useraccount" className={loc.includes("useraccount") ? 'DhashNavLink active' : 'DhashNavLink'}>
          <img className='DashNavIcon' src={adopt}/>
          <img src={loc.includes("useraccount") ? WhiteUser : User}/>
        </Link> */}
    </div>
  )
}

const MobileNav = memo(MemomisedMobileNav)

export default MobileNav