import React from 'react'
import DashNavUser from '../../Reusable/DashNavUser'
import PageHeaderSticky from '../../Reusable/PageHeaderSticky'
import './style.scss'
import UpcomingAppointments from '../../Reusable/UpcomingAppointments'

const UserDashboard = () => {
    const fName = localStorage.getItem('fName')
  return (
    <div className='appLanding'>
        <DashNavUser />
        <div className='dashboardWrapper'>
            <PageHeaderSticky heading={`${fName ? "Hi, " + fName + "!" : "Woof woof!"}`} subHeading={"Login to see more details"}/>
            <div className='userDashboard'>
                <UpcomingAppointments />
            </div>
        </div>

    </div>
  )
}

export default UserDashboard