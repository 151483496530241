import OtpInput from 'react-otp-input';
import { useEffect, useState } from 'react';
import PhoneInput from "react-phone-input-2"
import { Button } from '@mui/material';
import otpApi from '../../../services/otpApi';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../Auth/AuthContext";
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux'
import { newNotification } from '../../../features/app/appSlice';
import { loginUser } from '../../../features/user/userSlice';

const InputOtp = ({setAppState, otpVerifyDto, prevPath, isPopup, setOpen}) => {
    const { isLoggedIn, login, logout } = useAuth();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [otp, setOtp] = useState('');

    const setUserInLocalStorage = (response) => {
        localStorage.setItem('userId', response.user?.userId);
        localStorage.setItem('email', response.user?.email);
        localStorage.setItem('fName', response.user?.fName);
        localStorage.setItem('lName', response.user?.lName);
        localStorage.setItem('phoneNo', response.user?.phoneNo);
        localStorage.setItem('role', response.user?.role);
        localStorage.setItem('profileStatus', response.profileStatus)
        login();
        isFirstLogin(response);
        if(isPopup){
            setOpen(false)
        }else{
            navigate(prevPath)
        }
    }

    const isFirstLogin = (response) => {
        if(response?.user?.fName){
            dispatch(newNotification(`Welcome to Poochku ${response?.user?.fName ? response?.user?.fName : ""}`))
            dispatch(loginUser({
                userId : response?.user?.userId,
                email :  response?.user?.email,
                fName : response?.user?.fName,
                lName : response?.user?.lName,
                phoneNo : response?.user?.phoneNo,
                role : response?.user?.role,
                profileStatus : response?.profileStatus
            }))
        }
    }

    const verifyOtp = async () => {
        setLoading(true)
        // console.log(otp);
        const response = await otpApi.validateOtp(otpVerifyDto.mobileNumber, otp, otpVerifyDto.verificationId);
        if(response.responseCode===200){
            setUserInLocalStorage(response)
            setAppState('success');
            setLoading(false)
        } else {
            setAppState('failed');
            setLoading(false)
        }
    }
    return (
        <div className='otpVerificationWrapper'>
            <p>Enter OTP</p>
            <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span>{`  `}</span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                    width: "2.5rem",
                    fontFamily: "changa one",
                    marginRight: "20px",
                    fontSize: "2rem"
                }}
              />
            <button onClick={verifyOtp} disabled={loading}> {loading ? <CircularProgress sx={{color: "white", height: "1rem !important", width: "1rem !important"}} />  : "Verify"} </button>
        </div>
    );
};

export default InputOtp;