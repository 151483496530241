import React, { useState } from 'react'

const FirstLogin = () => {
    const [details, setDetails] = useState({});
    const [view, setView] = useState(1);

    const handleChange= (e) => {
        setDetails({
            ...details,
            [e.target.name] : e.target.value
        })
    }
  return (
    <div className='firstLoginWrapper'>
        <h1>*sniff sniff*</h1>
        <label>Enter your full name</label>
        <input type="text" onChange={handleChange} value={details?.name} name='name'/>
        <label>Enter your email</label>
        <input type="email" onChange={handleChange} value={details?.email} name='email' />
        <button>Next</button>
    </div>
  )
}

export default FirstLogin